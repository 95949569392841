@import "settings";

body.overflowed {
	overflow:hidden;
}
body.page-template-default {
	.category-posts {
		width:100% !important;
	}
	.wrapper {
		padding-top: 0 !important;
	}
}
body.archive {
	.insights-section {
		.col25 {
			.inside {
				padding-bottom: 70px;
			}
			.title {
				padding:30px 0 0 0;
			}
			p {
				padding: 0;
				margin-bottom: 0;
			}
			.details {
				display:block !important;
				border-top:1px solid #c2cbd8;
			}
		}
	}
}

.page {
	/*overflow: hidden;*/
}

.container {
	@include clearfix;
}

.section {
	overflow:hidden;
	h2 {
		font-size:4.6rem;
	}
}

.breadcrumbs {
	font-size:14px;
	margin:-20px 0 40px 0;
}

a.btn {
	display:inline-block;
	font-size:15px;
	font-weight:700;
	color:$green-color;
	border:1px solid $green-color;
	padding:13px 60px;
	position:relative;
	&:after {
		content:"";
		display:block;
		position:absolute;
		left:0;
		right:0;
		bottom:0;
		height:2px;
		background:$green-color;
		transform:scale(0,1);
		transform-origin: center left;
		transition:all 0.3s ease-in-out;
	}
	&:hover {
		&:after {
			transform:scale(1,1);
		}
	}
}

a.arrow-link {
	font-size:15px;
	font-weight:700;
	display:inline-block;
	color:$blue-color;
	position:relative;
	padding:7px 40px 9px 0;
	transition:all 0.4s ease-in-out;
	&:hover {
		&:after, &:before {
			right:-20px;
		}
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:2px;
		background:$link-color;
		transition:all 0.4s ease-in-out;
	}
	&:before {
		content:"";
		display:block;
		position:absolute;
		top:50%;
		right:0;
		transform:translateY(-50%);
		background:url(../images/arrow-blue.svg) no-repeat center center;
		width:18px;
		height:12px;
		background-size:contain;
		transition:all 0.4s ease-in-out;
	}
	&.arrow-link-white {
		color:#fff;
		&:before {
			background-image:url(../images/arrow-white.svg);
		}
	}
}

a.simple-link {
	display:inline-block;
	position:relative;
	color:$blue-color;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:2px;
		background:$blue-color;
		transition:all 0.4s ease-in-out;
		z-index:1;
	}
	&:before {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:2px;
		background:$green-color;
		transition:all 0.4s ease-in-out;
		transform-origin:center left;
		transform:scale(0, 1);
		z-index:2;
	}
	&:hover {
		color:$green-color;
		&:before {
			transform:scale(1,1);
		}
	}
}

.social-btn {
	display:inline-block;
	position:relative;
	color:$blue-color;
	padding:10px 0 5px 43px;
	font-weight:300;
	font-size:14px;
	margin-bottom: 10px;
	&:before {
		content:"";
		display:block;
		position:absolute;
		top:50%;
		left:0;
		transform:translateY(-50%);
		width:32px;
		height:32px;
		margin-top: 3px;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:43px;
		right:0;
		height:2px;
		background:$green-color;
		transition:all 0.4s ease-in-out;
		transform-origin:center left;
		transform:scale(0, 1);
		z-index:2;
	}
	&:hover {
		color:$green-color;
		&:after {
			transform:scale(1, 1);
		}
	}
	&.fb-btn:before {
		background:url(../images/fb.svg);
	}
	&.ln-btn:before {
		background:url(../images/ln.svg);
	}
	&.insta-btn:before {
		background:url(../images/instagram.svg);
	}
}

header {
	position:fixed;
	z-index:15;
	top:0;
	left:0;
	right:0;
	background:#fff;
	height:92px;
	a.logo {
		display:block;
		float:left;
		width:112px;
		margin-top: 30px;
		margin-right: 100px;
	}
	nav {
		float:left;
		margin:0;
		padding:0;
		> ul {
			margin:0 0 0 -15px;
			padding:0;
			> li {
				display:inline-block;
				margin:0 15px;
				font-size:15px;
				position:relative;
				&:after {
					content:"";
					display:block;
					position:absolute;
					bottom:0;
					left:0;
					right:0;
					height:3px;
					background:$link-color;
					transition:all 0.3s ease-in-out;
					transform:scale(1,0);
					transform-origin: bottom center;
				}
				&:hover, &.current-menu-item {
					&:after {
						transform:scale(1,1);
					}
					a {
						color:$link-color;
					}
				}
				&:hover {
					.dropdown {
						opacity:1;
						visibility: visible;
						transform:translateY(0);
					}
				}
				> a {
					height:92px;
					line-height:92px;
					display:block;
					padding:0 7px;
					color:$blue-color;
				}
				.dropdown {
					position:fixed;
					top:92px;
					left:0;
					right:0;
					padding-top: 7px;
					transition:all 0.3s ease-out;
					opacity:0;
					visibility: hidden;
					transform:translateY(20px);
					z-index:16;
					.container {
						background:#fff;
						padding:50px;
					}
					.box {
						display:inline-block;
						padding-right: 12rem;
						vertical-align: top;
					}
					h4 {
						margin:0;
						padding:0 0 10px 0;
						font-size:18px;
					}
					ul {
						margin:0;
						padding:0 0 10px 30px;
						li {
							padding:5px 0;
							font-size:16px;
							&.current-menu-item {
								a {
									color:$link-color;
									border-bottom: 1px solid $link-color;
								}
							}
							a {
								color:$blue-color;
								display:block;
								padding:5px 0;
								border-bottom: 1px solid transparent;
								transition:all 0.3s;
								&:hover {
									color:$link-color;
									border-bottom: 1px solid $link-color;
								}
							}
						}
					}
				}
			}
		}
	}
	a.mail {
		width:20px;
		display:block;
		float:right;
		margin-top: 35px;
		&:hover {
			.st0 {
				fill:$link-color;
			}
		}
		.st0 {
			transition:all 0.3s;
		}
	}
}
.slick-slider .image.animate {
  animation: heroZoom 7s ease-in-out;
}
@keyframes heroZoom {
  from { transform: scale(1.0,1.0);  }
  to { transform: scale(1.1,1.1);  }
}
.hero-slider {
	overflow: hidden;
	position:relative;
	margin:0 !important;
	.slide {
		height:100vh;
		position:relative;
	}
	.image {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:no-repeat center center;
		background-size:cover;
	}
	.content {
		position:absolute;
		top:50%;
		transform:translateY(-40%);
		left:0;
		right:0;
		color:#fff;
		z-index:2;
		.inside {
			max-width:790px;
			opacity:0;
			&.content-reveal {
				animation: contentReveal 1s ease-in-out forwards;
			}
			@keyframes contentReveal {
			  from { transform: translateY(100px); opacity:0;  }
			  to { transform: translateY(0); opacity:1;  }
			}
		}
	}
	h1, h2 {
		font-size:4.6rem;
		position:relative;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:0;
			left:-60px;
			width:40px;
			height:38px;
			transform:translateY(50%);
			margin-top: 5px;
			background:url(../images/logo-mark-green.svg) no-repeat center center;
			background-size:contain;
		}
	}
	p {
		font-size:19px;
	}
	a.arrow-link {
		margin-top: 50px;
	}
}
.slick-track .slide:nth-child(2) {
	h1,h2:after {
		background-image:url(../images/logo-mark-blue.svg);
	}
}
.slick-track .slide:nth-child(3) {
	h1,h2:after {
		background-image:url(../images/logo-mark-red.svg);
	}
}
.slick-track .slide:nth-child(4) {
	h1,h2:after {
		background-image:url(../images/logo-mark-yellow.svg);
	}
}
.slick-track .slide:nth-child(5) {
	h1,h2:after {
		background-image:url(../images/logo-mark-white.svg);
	}
}
.hero-dots {
	float:left;
	width:100%;
	margin-top: -100px;
	position:relative;
	z-index:3;
	.slick-dots {
		position:static;
		width:auto;
		margin:0;
		padding:0;
		text-align: left;
		li {
			width:25px;
			height:3px;
			&.slick-active {
				button {
					background:$link-color;
					height:5px;
				}
			}
			button {
				width:25px;
				height:3px;
				padding:0;
				background:#fff;
				&:before {
					display:none;
				}
			}
		}
	}
}

.introduction {
	padding:10rem 0 10rem 0;
	color:$blue-color;
	text-align: center;
	&.type2 {
		padding-top: 15rem;
		h2 {
			position:relative;
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:-120px;
				left:50%;
				transform:translate(-50%,0);
				width:200px;
				height:97px;
				background:url(../images/graphic-arrow.svg) no-repeat center center;
				background-size:contain;
			}
		}
		.container {
			max-width: 730px;
			margin:0 auto;
		}
	}
	h3 {
		color:$link-color;
	}
	p {
		font-size:22px;
		line-height:1.6em;
		font-weight:300;
	}
	.buttons {
		margin-top:30px;
		a.arrow-link {
			margin:0 45px;
		}
	}
}

.our-difference {
	padding:15rem 0;
	color:$blue-color;
	background:#e7eaef;
	h2 {
		text-align: center;
		margin-bottom: 10rem;
	}
	i.difference1 svg {
		width:88px;
	}
	i.difference2 svg {
		width:120px;
	}
	i.difference3 svg {
		width:120px;
	}
	i.difference4 svg {
		width:77px;
	}
	p {
		font-size:20px;
		font-weight:300;
	}
	.row {
		@include clearfix;
		&:nth-child(2n) {
			.block {
				float:right;
				text-align: right;
			}
		}
		.block {
			float:left;
			max-width:380px;
		}
	}
}

.who-we-worked-with {
	padding:13rem 0;
	color:$blue-color;
	.pageAlignSpecial {
		position:relative;
	}
	.slick-arrow{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		left:-100px;
		right:auto;
		width:19px;
		height:37px;
		&:before {
			transform:rotate(0deg);
			content:"";
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background:url(../images/arrow2.svg);
		}
	}
	.slick-arrow.slick-prev {
		left:auto;
		right:-100px;
		&:before {
			transform:rotate(180deg);
		}
	}
	.logo-slider {
		margin:8rem 0 2rem 0;
		.slide {
			padding:25px;
			img {
				max-width:100%;
				max-height:100%;
				filter: sepia(100%) hue-rotate(180deg) saturate(300%) brightness(.8);
				&:hover {
					filter: sepia(0%) hue-rotate(0deg) saturate(100%) brightness(1);
				}
			}
		}
	}
}

.proud-members {
	padding:10rem 0;
	background:#f7f5f0;
	color:$blue-color;
	text-align: center;
	p {
		font-size:22px;
		font-weight:300;
	}
}
.wrapper {
	@include clearfix;
	padding-top: 5rem;
	.category-posts {
		float:left;
		width:70%;
		h1 {
			font-size:4.6rem;
		}
		h2 {
			font-size:4rem;
		}
		h3 {
			font-size:3.5rem;
		}
		h4 {
			font-size:20px;
		}
		h5 {
			font-size:16px;
		}
	}
	.category-side-menu {
		float:right;
		width:30%;
		padding-left: 70px;
		h3 {
			font-size:24px;
			margin-bottom: 45px;
		}
		ul {
			list-style:none;
			margin:0;
			padding:0;
			li {
				display:block;
				border-bottom: 1px solid #d0d6df;
				border-top: 1px solid #d0d6df;
				margin-top: -1px;
				position:relative;
				transition:all 0.3s;
				&:hover {
					z-index:1;
					border-color:$link-color;
					a {
						color:$link-color;
					}
				}
				a {
					display:block;
					padding:15px 0;
					font-size:20px;
					color:$blue-color;

				}
			}
		}
	}
}
.insights-section {
	background:#e8ebef;
	color:$blue-color;
	padding:10rem 0;
	&.type2 {
		padding-top: 0;
		background:transparent;
	}
	&.category-page {
		.col25 {
			width:33.3333%;
		}
		h2 {
			margin-top: 0;
			padding-top: 0;
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		margin:0 -12px;
	}
	.my-lazyloader-wrapper {
		width:100%;
	}
	.my-lazyloader-content {
		display:flex;
		flex-wrap:wrap;
		margin:0 -12px;
	}
	.col25 {
		padding:12px;
		float:left;
		width:25%;
		.inside {
			background:#fff;
			height:100%;
			position:relative;
			//padding-bottom: 70px;
			&:hover {
				p {
					color:$link-color;
				}
			}
		}
		a:hover {
			.title {
				color:$green-color;
			}
			.img-resize {
				transform:scale(1.1,1.1);
			}
		}
		a span {
			display:block;
		}
		.picture {
			height:0;
			padding-bottom: 80%;
			position:relative;
			overflow: hidden;
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				transition:all 0.6s ease-in-out;
				img {
					max-width:none;
				}
			}
		}
		.title {
			padding:30px 20px 0 20px;
			font-weight:700;
			font-size:22px;
			color:$blue-color;
			transition:all 0.3s;
		}
		p {
			padding:0 20px 0 20px;
			transition:all 0.3s;
		}
		.details {
			border-top: 2px solid #f7f7f7;
			padding:20px;
			font-size:15px;
			color:#8a99af;
			font-weight:300;
			position:absolute;
			bottom:0;
			left:0;
			right:0;
			display:none !important;
		}
	}
	.buttons, .my-lazyloader-btn-container {
		padding-top:7rem;
		text-align: center;
	}
}

.grow-together {
	position:relative;
	&.type2 {
		&:after {
			background:$link-color;
		}
		.container {
			.right {
				background:$link-color;
				.image {
					width:401px;
					height:352px;
					background-image:url(../pictures/grow-together2.png);
					&:after {
						background-image:url(../images/grow-together2.svg);
					}
				}
			}
		}
	}
	&:before {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:50%;
		bottom:0;
		background:#f7f5f0;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		right:0;
		width:50%;
		bottom:0;
		background:$blue-color;
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		position:relative;
		z-index:1;
		h2 {
			font-size:4.1rem;
		}
		.left {
			width:50%;
			color:$blue-color;
			padding:13rem 10rem 13rem 0;
		}
		.social-buttons {
			border-top: 2px solid #c7ced6;
			padding-top:7rem;
			margin-top: 8rem;
			a {
				margin-right: 30px;
			}
		}
		.right {
			float:right;
			width:50%;
			position:relative;
			.image {
				width:426px;
				height:372px;
				background:url(../pictures/grow-together.png) no-repeat center center;
				background-size:contain;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				position:absolute;
				margin-left: 5rem;
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					background:url(../images/grow-together.svg);
					background-size:contain;
				}
			}
		}
	}
}

.ad {
	background:url(../pictures/people-make-it-happen.jpg) no-repeat center right;
	background-size:cover;
	color:#fff;
	text-align: center;
	font-size:5rem;
	font-weight:700;
	.content {
		padding:15rem 0;
	}
}

footer {
	background:$blue-color;
	color:#fff;
	padding-top: 10rem;
	a {
		color:#fff;
		&:hover {
			color:$link-color;
		}
	}
	.top {
		font-size:15px;
		.container:first-child {
			.col30:last-child {
				a {
					text-decoration: underline;
				}
			}
		}
		.col30 {
			line-height:25px;
			float:left;
			width:33.3333%;
			&:first-child {
				font-weight:400;
				font-size:23px;
				h3 {
					font-weight:400;
				}
			}
		}
		ul {
			margin:0;
			padding:0;
			> li {
				position:relative;
				&:before {
					content:"";
					display:block;
					width:15px;
					height:2px;
					position:absolute;
					top:16px;
					left:-25px;
					background:$link-color;
					transform-origin: center right;
					transform:scale(0,1);
					transition:all 0.3s ease-out;
				}
				&:hover {
					&:before {
						transform:scale(1,1);
					}
				}
			}
			li {
				font-weight:700;
				a {
					color:#fff;
					display:inline-block;
					padding:5px 0;
				}
				ul {
					padding-left: 25px;
					li {
						&:before {
							display:none;
						}
						font-weight:300;
						a {
							&:before {
								content:"- ";
							}
						}
					}
				}
				&:hover {
					> a {
						color:$link-color;
					}
				}
			}
		}
		.logo {
			width:110px;
		}
		form {
			margin:10px 0 30px 0;
			padding:0;
			position:relative;
			@include clearfix;
			&:before {
				content:"";
				display:block;
				height:55px;
				width:15%;
				background:url(../images/arrow-white.svg) no-repeat center center;
				background-size:30%;
				position:absolute;
				top:0;
				right:0;
				pointer-events: none;
				transition:all 0.3s ease-out;
				&:hover {
					background-size:40%;
				}
			}
			input {
				float:left;
				width:84%;
				display:block;
				border:1px solid #fff;
				background:transparent;
				color:#fff;
				font-size:13px;
				height:55px;
				line-height:55px;
				padding:0 15px;
				transition:all 0.3s;
			}
			input[type="email"]:focus {
				border-color:$link-color;
			}
			input[type="submit"] {
				background:$link-color;
				width:15%;
				float:right;
				border:none;
				cursor:pointer;
			}
			::-webkit-input-placeholder { /* Edge */
			  color: #fff;
			}
			:-ms-input-placeholder { /* Internet Explorer 10-11 */
			  color: #fff;
			}
			::placeholder {
			  color: #fff;
			}
		}
		.container:last-child {
			margin-top: 10px;
			.col30:first-child {
				font-size:15px;
				img {
					display:inline-block;
					margin:20px 40px 0 0;
				}
			}
			.col30:nth-child(2) {
				padding-top: 80px;
				a {
					margin-right: 60px;
					font-weight:400;
				}
			}
			.col30:nth-child(3) {
				padding-top: 85px;
				text-align: right;
			}
			.social-btn {
				width:32px;
				height:32px;
				background:#fff;
				display:inline-block;
				border-radius:100%;
				padding:0;
				margin-left: 10px;
				transition:all 0.3s;
				&:hover {
					background:$link-color;
				}
				&.fb-btn:before {
					background:url(../images/fb2.svg) no-repeat center center;
					background-size:30%;
				}
				&.ln-btn:before {
					background:url(../images/ln2.svg) no-repeat center center;
					background-size:45%;
					margin-left: 1px;
				}
				&.insta-btn:before {
					background:url(../images/instagram2.svg) no-repeat center center;
					background-size:100%;
				}
				&:before {
					margin:0;
				}
				&:after {
					display:none;
				}
			}
		}
	}
	.bottom {
		margin-top: 27px;
		padding:40px 0;
		border-top: 1px solid #5c708f;
		font-size:13px;
		font-weight:300;
		.left {
			float:left;
			ul {
				margin:0 0 0 -10px;
				padding:0;
				li {
					display:inline-block;
					a {
						display:block;
						padding:10px;
					}
				}
			}
		}
		.right {
			float:right;
		}
	}
}

.submenu {
	border-top: 1px solid #d0d6df;
	&.submenu-top {
		margin-top: 92px;
		text-align: left;
		position:fixed;
		left:0;
		right:0;
		top:0;
		background:#fff;
		z-index:14;
		height:60px;
		ul {
			display:inline-block;
			margin-left: 212px;
			li {
				&:first-child {
					margin-left: -25px;
				}
				&.active, &:hover {
					a {
						&:after {
							display:none;
						}
					}
				}
			}
		}
	}
	&.submenu-bottom {
		background:#f7f5f0;
		border:none;
		border-bottom: 1px solid #d0d6df;
	}
	ul {
		margin:0 0 0 -25px;
		padding:0;
		font-size:15px;
		@include clearfix;
		li {
			float:left;
			display:inline-block;
			padding:0 25px;
			margin-bottom: -2px;
			a {
				position:relative;
				float:left;
				color:#73849f;
				height:60px;
				display:block;
				line-height:60px;
				transition:all 0.3s;
				&:after {
					content:"";
					display:block;
					position:absolute;
					bottom:1px;
					left:0;
					right:0;
					height:1px;
					background:$link-color;
					transition:all 0.3s ease-in-out;
					transform:scale(1,0);
					transform-origin: bottom center;
				}
			}
			&:hover, &.current-menu-item {
				a {
					color:$link-color;
					&:after {
						transform:scale(1,1);
					}
				}
			}
		}
	}
}

.subpage-hero {
	background:no-repeat center center;
	background-size:cover;
	padding:12rem 0;
	&.margin-top {
		margin-top: 92px;
	}
	&.margin-top2 {
		margin-top: 152px;
	}
	&.no-bg {
		padding:8rem 0 6rem 0;
		border-top: 1px solid #d0d6df;
		.content {
			color:$blue-color;
		}
	}
	&.organisational-development {
		h1,h2:after {
			background-image:url(../images/logo-mark-yellow.svg);
		}
	}
	&.training-and-development {
		h1,h2:after {
			background-image:url(../images/logo-mark-red.svg);
		}
	}
	&.single-post-hero {
		padding:28rem 0 4rem 0;
		background-color:$link-color;
		h2:after {
			display:none !important;
		}
	}
	.content {
		max-width:790px;
		color:#fff;
	}
	h1, h2 {
		font-size:4.6rem;
		position:relative;
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:0;
			left:-60px;
			width:40px;
			height:38px;
			transform:translateY(50%);
			margin-top: 5px;
			background:url(../images/logo-mark-green.svg) no-repeat center center;
			background-size:contain;
		}
	}
	p {
		font-size:19px;
	}
}

.what-we-do-overview {
	padding:10rem 0;
	color:$blue-color;
	&.beige {
		background:#f7f5f0 !important;
	}
	&.white {
		background:#fff !important;
	}
	h2 {
		.underlined {
			display:inline-block;
			position:relative;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:-13px;
				left:0;
				right:0;
				height:0;
				padding-bottom: 6%;
				background:url(../images/underline.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	&.psychometric-overview {
		background:#f7f5f0;
		h2, .col30 {
			color:$link-color;
		}
	}
	&.organisational-development {
		&.type2 {
			background:#f7f5f0;
		}
		h2, .col30 {
			color:#e5b433;
		}
		.buttons {
			a.arrow-link:after {
				background:#e5b433;
			}
		}
		.top .right .image:after {
			background-image:url(../images/organisational-development-svg.svg);
		}
	}
	&.training-and-development {
		background:#f7f5f0;
		h2, .col30 {
			color:#ef4130;
		}
		.buttons {
			a.arrow-link:after {
				background:#ef4130;
			}
		}
		.top .right .image:after {
			background-image:url(../images/training-and-development-svg.svg);
		}
	}
	.top {
		padding-bottom: 3rem;
		.container {
			display: flex;
		}
		.left {
			float:left;
			width:48%;
		}
		.right {
			float:right;
			width:50%;
			display: flex;
  			align-items: center;
			justify-content: flex-end;
			.image {
				float:right;
				position:relative;
				&:after {
					content:"";
					display:block;
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					background:url(../images/psychometric-assessments-svg.svg) no-repeat center center;
					background-size:cover;
				}
			}
		}
	}
	.bottom {
		border-top: 1px solid #d0d6df;
		padding-top: 5rem;
		.col30 {
			float:left;
			width:30%;
			ul {
				list-style:none;
				margin:0;
				padding:0;
				li {
					margin:10px 0;
				}
			}
		}
		.buttons {
			margin-top: 5rem;
			a {
				margin-right: 60px;
			}
		}
	}
}

.how-we-work {
	background:#f7f5f0;
	color:$blue-color;
	text-align: center;
	padding-bottom: 10rem;
	.image-ad {
		margin-bottom: 10rem;
	}
	h4 {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	h2 {
		padding-top: 0;
		margin-top: 0;
	}
	.row {
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		&:nth-child(2n) {
			flex-direction: row-reverse;
			.left {
				text-align: left;
			}
			.right {
				text-align: right;
			}
		}
		.nr {
			font-weight:300;
		}
		h3 {
			margin:0;
			font-size:2.6rem;
		}
		p {
			margin-top: 0;
			font-weight:300;
			fonst-style: italic;
		}
		.left {
			float:left;
			text-align: right;
			width:50%;
			padding:40px;
		}
		.right {
			float:left;
			width:50%;
			text-align: left;
			padding:40px;
			i svg {
				width:110px;
				height:110px;
			}
		}
	}
}

.get-in-touch {
	background:#93d4e3;
	padding:10rem 0;
	color:$blue-color;
	&.type2 {
		padding:5rem 0 10rem 0;
		background:#e7eaee;
		input, textarea {
			border-color:#b8c1ce;
		}
		input[type="submit"] {
			color:$link-color;
			border-color:$link-color;
			&:hover {
				background:$link-color;
			}
		}
	}
	&.type3 {
		margin-top: 10rem;
		form {
			margin-top:0;
		}
		.container {
			display:flex;
		}
		.left {
			float:left;
			width:50%;
		}
		.right {
			float:right;
			width:50%;
			display: flex;
		    align-items: center;
		    justify-content: flex-end;
			.image {
				float:right;
				position:relative;
				&:after {
					content: "";
				    display: block;
				    position: absolute;
				    top: 0;
				    left: 0;
				    right: 0;
				    bottom: 0;
				    background: url(../images/contact.svg);
				    background-size: contain;
				}
			}
		}
		.col50 {
			width:100%;
		}
	}
	h2 {
		margin-bottom: 0;
	}
	form {
		margin:7rem -30px 0 -30px;
	}
	.col50 {
		padding:15px 30px;
		float:left;
		width:50%;
	}
	.col100 {
		padding:15px 30px;
		float:left;
		width:100%;
	}
	input, textarea {
		border:1px solid #fff;
		background:none;
		display:block;
		width:100%;
		padding:13px;
		margin-top: 5px;
		transition:border-color 0.3s;
		color:$blue-color;
		&:focus {
			border-color:$blue-color;
		}
	}
	textarea {
		height:200px;
		min-height:200px;
		max-height:500px;
		max-width:100%;
		min-width:100%;
	}
	input[type="submit"] {
		border-color:$blue-color;
		color:$blue-color;
		width:auto;
		padding:13px 40px;
		font-weight:700;
		-webkit-appearance:none;
		transition:all 0.3s;
		cursor:pointer;
		&:hover {
			background:$blue-color;
			color:#fff;
		}
	}
}

.faq {
	background:#fff;
	padding:13rem 0;
	color:$blue-color;
	&.yellow-hovers {
		.row {
			.question {
				&.opened {
					color:#e4b345;
				}
			}
		}
	}
	&.red-hovers {
		.row {
			.question {
				&.opened {
					color:#ee4130;
				}
			}
		}
	}
	.row {
		@include clearfix;
		border-bottom: 1px solid #d9dee4;
		.question {
			padding:30px 35px 30px 0;
			font-size:30px;
			font-weight:700;
			position:relative;
			transition:all 0.3s;
			cursor:pointer;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:50%;
				right:0;
				transform:translateY(-50%);
				width:24px;
				height:1px;
				background:$blue-color;
				transition:all 0.3s;
			}
			&:before {
				content:"";
				display:block;
				position:absolute;
				top:50%;
				right:0;
				transform:translateY(-50%) rotate(90deg);
				width:24px;
				height:1px;
				background:$blue-color;
				transition:all 0.3s;
			}
			&.opened {
				color:$link-color;
				&:before, &:after {
					background:$link-color;
				}
				&:before {
					transform:translateY(-50%);
				}
			}
		}
		.answer {
			display:none;
			padding-bottom: 50px;
			h1, h2, h3, h4, h5, h6 {
				margin:20px 0 0 0;
			}
			h4 {
				font-weight: 600;
				letter-spacing: .05em;
				padding-bottom: 10px;
				font-size: 20px;
			}
			p {
				margin: 5px 0;
			}
			ul {
				list-style:none;
				margin:5px 0 20px 0;
				padding-left: 0;
				li {
					position:relative;
					&:before {
						content:"- ";
						float:left;
						margin-right:7px;
					}
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.study-content, .my-lazyloader-content {
	margin: 0 -10px;
	@include clearfix;
	display:flex;
	flex-wrap:wrap;
}
.box {
	@include clearfix;
	display:flex;
	flex-wrap:wrap;
	width:50%;
	float:left;
	padding:10px;
	.left {
		float:left;
		width:45%;
		position:relative;
		overflow: hidden;
		a:hover {
			.img-resize {
				transform:scale(1.1,1.1);
			}
		}
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			transition:all 0.5s ease-out;
			img {
				max-width:none;
			}
		}
	}
	.right {
		padding:20px 30px 20px 35px;
		width:55%;
		background:#fff;
		float:right;
		transition: all 0.3s;
		&:hover {
			color:$link-color;
			a {
				color:$link-color;
			}
		}
		a {
			color:$blue-color;
		}
	}
}
.case-studies {
	background:#e7eaee;
	color:$blue-color;
	padding:10rem 0;
	.buttons, .my-lazyloader-btn-container {
		margin-top: 40px;
		text-align: center;
	}
}

.testimonials {
	background:#e7eaee;
	color:$link-color;
	text-align: center;
	&.yellow-hovers {
		color:#e4b345;
	}
	&.red-hovers {
		color:#ee4130;
	}
	.container {
		padding:10rem 0;
		//border-bottom: 1px solid #a1acbe;
		//border-top: 1px solid #a1acbe;
	}
	.image {
		margin:50px 0 10px 0;
		img {
			display:inline-block;
			max-height:69px;
		}
	}
	.content {
		font-size:3.4rem;
		font-weight:700;
		max-width:950px;
		margin:0 auto;
		&:before {
			content:"“";
		}
		&:after {
			content:"”";
		}
	}
	.person {
		.name {
			font-weight:600;
		}
		.postiion {
			font-weight:300;
		}
	}
	.slick-dots {
		position:static;
		width:auto;
		margin:40px 0 0 0;
		padding:0;
		li {
			width:25px;
			height:3px;
			&.slick-active {
				button {
					background:$link-color;
					height:4px;
				}
			}
			button {
				width:25px;
				height:2px;
				padding:0;
				background:$blue-color;
				&:before {
					display:none;
				}
			}
		}
	}
}

.our-approach {
	padding:10rem 0 5rem 0;
	background:#f6f4ef;
	color:$blue-color;
	&.no-bg {
		background:#fff;
	}
	&.category-version {
		.featured-post {
			border-bottom: 1px solid #d0d6df;
			margin-bottom: 40px;
			padding-bottom:10px;
			font-weight:700;
		}
		.right {
			padding-right: 7rem;
			padding-left: 0 !important;
			.details {
				font-size: 15px;
				color: #8a99af;
				display:none !important;
			}
		}
		.title {
			font-size:4rem;
			font-weight:700;
			color:$blue-color;
			transition:all 0.3s;
			&:hover {
				color:$link-color;
			}
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		flex-direction: row-reverse;
	}
	.left {
		float:left;
		width:50%;
	}
	.right {
		float:right;
		width:50%;
		padding-left: 7rem;
		h2 {
			margin-top: 0;
		}
	}
}

.our-values {
	padding:2rem 0 15rem 0;
	background:#f6f4ef;
	color:$blue-color;
	.values {
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
		margin:0 -21px;
		.value {
			float:left;
			width:33.3333%;
			padding:21px;
			position:relative;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:21px;
				right:21px;
				height:1px;
				background:$link-color;
			}
			&:nth-child(2) {
				h3:after {
					background:url(../images/logo-mark-blue.svg) no-repeat center center;
				}
				&:after {
					background:#92d3e2;
				}
			}
			&:nth-child(3) {
				h3:after {
					background:url(../images/logo-mark-yellow.svg) no-repeat center center;
				}
				&:after {
					background:#e4b345;
				}
			}
		}
		.image {
			height:0;
			padding-bottom: 60%;
			position:relative;
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				overflow: hidden;
				img {
					max-width:none;
				}
			}
		}
		h3 {
			font-size:26px;
			font-weight:600;
			position:relative;
			padding-left: 25px;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:10px;
				left:0;
				width:14px;
				height:14px;
				background:url(../images/logo-mark-green.svg) no-repeat center center;
				background-size:cover;
			}
		}
		p {
			font-size:18px;
			font-weight:300;
		}
	}
}

.our-story {
	background:#fff;
	color:$blue-color;
	position:relative;
	.image {
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		width:50%;
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			bottom:0;
			right:0;
			overflow: hidden;
			img {
				max-width:none;
			}
		}
	}
	.right {
		float:right;
		width:50%;
		padding:7rem 0 10rem 75px;
		.buttons {
			@include clearfix;
			a {
				float:left;
				clear:left;
				margin-top: 40px;
			}
		}
	}
}

.where-we-are {
	padding:10rem 0 5rem 0;
	background:#e7eaee;
	color:$blue-color;
	&.no-bg {
		background:transparent;
	}
	.map-area {
		@include clearfix;
		display:flex;
		flex-wrap: wrap;
		.left {
			background:$blue-color;
			float:left;
			width:27%;
			@include clearfix;
			color:#fff;
			padding:50px;
			font-size:18px;
			h3 {
				color:$link-color;
			}
			a.arrow-link {
				margin-top: 15rem;
			}
		}
		.map {
			float:right;
			width:73%;
			position:relative;
			iframe {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
			}
		}
	}
}

.filter-insights {
	color:$blue-color;
	text-align: left;
	.container {
		border-top:1px solid #d0d6df;
		padding-top: 20px;
	}
	.filter-text {
		float:left;
		font-size:18px;
		font-weight:300;
		margin-top: 6px;
		margin-right: 30px;
	}
	.custom-select {
	  position: relative;
	  font-size:15px;
	  display:inline-block;
	  margin:0 7px;
	}

	.custom-select select {
	  display: none; /*hide original SELECT element:*/
	}

	.select-selected {
		border:1px solid $link-color;
		color:$link-color;
		font-weight:700;
	}

	/*style the arrow inside the select element:*/
	.select-selected:after {
	  position: absolute;
	  content: "";
	  top: 18px;
	  right: 15px;
	  background:url(../images/arrow-green.svg) no-repeat center center;
	  background-size:contain;
	  width:14px;
	  height:7px;
	}

	/*point the arrow upwards when the select box is open (active):*/
	.select-selected.select-arrow-active:after {
	  transform:rotate(180deg);
	}

	/*style the items (options), including the selected item:*/
	.select-items div,.select-selected {
	  padding: 9px 40px 9px 17px;
	  cursor: pointer;
	  user-select: none;
	}

	/*style items (options):*/
	.select-items {
	  position: absolute;
	  top: 100%;
	  left: 0;
	  z-index: 99;
	  border:1px solid #8a99af;
	  background:#fff;
	  margin-top:-1px;
	  font-size:14px;
	  width:200px;
	  text-align: left;
	  transition:all 0.3s;
	  opacity:1;
	  visibility: visible;
	  transform:translateY(0px);
	  &.select-hide {

	  }
	}

	/*hide the items when the select box is closed:*/
	.select-hide {
		opacity:0;
  	  visibility: hidden;
  	  transform:translateY(20px);
	}

	.select-items div:hover, .same-as-selected {
	  color:$link-color;
	}
}

.featured-insight {
	color:$blue-color;
	padding:10rem 0;
	h4 {
		display:block;
		border-bottom: 1px solid #d0d6df;
		padding-bottom: 10px;
	}
	.container {
		padding-top: 40px;
	}
	.left {
		float:left;
		width:50%;
		padding-right: 75px;
		h2 {
			margin-top: 0;
			a {
				color:$blue-color;
				&:hover {
					color:$link-color;
				}
			}
		}
		.details {
			padding-top:5rem;
			font-size:15px;
			color:#8a99af;
			font-weight:300;
			display:none !important
		}
	}
	.right {
		float:right;
		width:50%;
	}
}

.quick-contact {
	color:$blue-color;
	.col30 {
		font-size:18px;
		h3 {
			font-size:30px;
			margin-bottom: 10px;
		}
		float:left;
		width:33.3333%;
		.social-buttons {
			a {
				margin-right: 20px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.people-make-it-happen {
	background:#f6f4ef;
	text-align: center;
	padding:7rem 0;
	h2 {
		color:$link-color;
		font-size:3rem;
	}
}

.to-top {
	background:$link-color;
	width:50px;
	height:50px;
	position:fixed;
	bottom:0;
	right:0;
	cursor:pointer;
	opacity:0;
	visibility: hidden;
	transition:all 0.3s;
	z-index:15;
	&.active {
		opacity:1;
		visibility: visible;
	}
	&:hover:after {
		margin-top: 0px;
	}
	&:after {
		margin-top: 3px;
		content:"";
		display:block;
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%) rotate(-90deg);
		background:url(../images/arrow-white.svg);
		width:15px;
		height:15px;
		background-repeat:no-repeat;
		background-position:center center;
		transition:all 0.3s;
	}
}

.mobile-menu {
	position:fixed;
	top:92px;
	left:0;
	width:100vw;
	bottom:0;
	z-index:18;
	background:#fff;
	border-top: 2px solid #d0d6df;
	padding-top:50px;
	overflow:scroll;
	opacity:0;
	visibility: hidden;
	transform:translateX(-100vw);
	transition:all 0.5s ease-out;
	&.active {
		opacity:1;
		visibility: visible;
		transform:translateX(0);
	}
	ul {
		margin:0;
		padding:0;
		list-style:none;
		font-size:20px;
		font-weight:600;
		li {
			position:relative;
			.arrow {
				display:block;
				position:absolute;
				top:0;
				right:0;
				background:url(../images/arrow-green.svg) no-repeat center center;
				width:45px;
				height:57px;
				cursor:pointer;
				background-size:55%;
				&.arrow-active {
					transform:rotate(180deg);
				}
			}
			&.current-menu-item {
				a {
					color:$link-color;
					border-bottom-color:$link-color;
				}
			}
			a {
				display:block;
				padding:15px 0;
				color:$blue-color;
				border-bottom: 1px solid #d0d6df;
				transition:all 0.3s;
				&:hover {
					color:$link-color;
					border-bottom-color:$link-color;
				}
			}
			ul {
				padding: 30px 0px;
    			background: #f2f2f3;
				display:none;
				li {
					padding-left:30px;
					a {
						font-size:16px;
					}
				}
			}
		}
	}
}

.quote {
	margin:30px 0;
	padding:30px;
	border-top:1px solid #d0d6df;
	border-bottom:1px solid #d0d6df;
	text-align: center;
	&.bigger-quote {
		.text {
			font-size:3rem;
		}
		.quote-author {
			font-size:18px;
		}
	}
	.text {
		font-family: $merryweather;
		font-style:italic;
		text-align: center;
		font-size:18px;
	}
	.quote-author {
		display:inline-block;
		font-size:16px;
		text-align: center;
		margin-top: 25px;
		&:before {
			content:"- ";
			float:left;
			display:inline-block;
			margin-right: 10px;
		}
	}
}

.author-and-share {
	margin-top: 40px;
	.author-name {
		color: #16325e;
    	font-weight: 700;
	}
	.author-position {
		font-style: italic;
	    font-size: 14px;
	    color: #7b7b7b;
	}
}
.share-icons {
	font-weight:700;
	.addthis_toolbox {
		margin-top: 10px;
	}
	a {
		background:$blue-color;
		width:25px !important;
		height:25px;
		border-radius:100%;
		position:relative;
		margin-right: 10px;
		transition:all 0.3s;
		&:hover {
			background:$link-color;
		}
		&:after {
			content:"";
			display:block;
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background:url(../images/facebook.png) no-repeat center center;
			background-size:50%;
		}
		span,svg {
			display:none;
		}
		&.ln:after {
			background:url(../images/linkedin.png) no-repeat center center;
			background-size:40%;
		}
	}
}

.single-page {
	background: #fff;
    color: #16325e;
    padding: 5rem 0;
	font-size:18px;
	.pageAlign3 {
		max-width:900px;
	}
	.smaller-text {
		p {
			color:#676767;
			font-size:12px;
		}
	}
	div.bigger-size {
		p {
			font-size:24px;
			line-height:1.6;
		}
	}
	p, ul, ol {
		font-size:15px;
	}
	p, ul, ol {
		line-height:2;
	}
	ul, ol {
		li {
			margin-top: 10px 0;
		}
	}
	hr {
		border:1px solid #d0d6df;
		margin:8rem 0;
		clear:both;
	}
	img {
		height:auto;
	}
	.post-details {
		border-top: 1px solid #d0d6df;
	    padding: 20px 0;
	    font-size: 15px;
	    color: #8a99af;
	    font-weight: 300;
		a {
			color: #8a99af;
			&:hover {
				color:$link-color;
			}
		}
	}
}

.related-posts {
	padding:10rem 0;
	color:$blue-color;
	background:#f7f5f0;
	h2 {
		display:block;
		font-size:4rem;
		margin-top: 0;
	}
}

.page-not-found {
	position:relative;
	height:80vh;
	background: linear-gradient(90deg,#132a4e 23%,#0f2240);;
	overflow:hidden;
}
.moon {
  background: linear-gradient(90deg, rgba(208,208,208,1) 48%, rgba(145,145,145,1) 100%);
  position: absolute;
  top: -100px;
  left: -300px;
  width: 900px;
  height: 900px;
  content: '';
  border-radius: 100%;
  box-shadow: 0px 0px 30px -4px rgba(0,0,0,0.5);
}

.moon__crater {
  position: absolute;
  content: '';
  border-radius: 100%;
  background: linear-gradient(90deg,#5f5f5f 23%,#272727);
  opacity: 0.6;
}

.moon__crater1 {
  top: 250px;
  left: 500px;
  width: 60px;
  height: 180px;
}

.moon__crater2 {
  top: 650px;
  left: 340px;
  width: 40px;
  height: 80px;
  transform: rotate(55deg);
}

.moon__crater3 {
  top: -20px;
  left: 40px;
  width: 65px;
  height: 120px;
  transform: rotate(250deg);
}

.star {
  background: grey;
  position: absolute;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 100%;
  transform: rotate(250deg);
  opacity: 0.4;
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes shimmer {
  from {opacity: 0;}
  to {opacity: 0.7;}
}

.star1 {
  top: 40%;
  left: 50%;
  animation-delay: 1s;
}

.star2 {
  top: 60%;
  left: 90%;
  animation-delay: 3s;
}

.star3 {
  top: 10%;
  left: 70%;
  animation-delay: 2s;
}

.star4 {
  top: 90%;
  left: 40%;
}

.star5 {
  top: 20%;
  left: 30%;
  animation-delay: 0.5s;
}

.error {
  position: absolute;
  left: 100px;
  top: 400px;
  transform: translateY(-60%);
  color:$blue-color;
}

.error__title {
  font-size: 120px;
  font-weight:700;
  color:$link-color;
  font-style:italic;
}

.error__subtitle {
  font-size: 24px;
  color:#fff;
  font-weight:600;
  margin-bottom: 5px;
}

.error__description {
  color:#fff;
  font-size:18px;
  margin-bottom: 40px;
}

.error__button {
  min-width: 7em;
  margin-top: 3em;
  margin-right: 0.5em;
  padding: 0.5em 2em;
  outline: none;
  border: 2px solid #2f3640;
  background-color: transparent;
  border-radius: 8em;
  color: #576375;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 0.75em;
}

.error__button:hover {
  color: #21252c;
}

.error__button--active {
  background-color: #e67e22;
  border: 2px solid #e67e22;
  color: white;
}

.error__button--active:hover {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
  color: white;
}

.astronaut {
  position: absolute;
  width: 185px;
  height: 300px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
}

.astronaut__head {
  background-color: white;
  position: absolute;
  top: 60px;
  left: 60px;
  width: 60px;
  height: 60px;
  content: '';
  border-radius: 2em;
}

.astronaut__head-visor-flare1 {
  background-color: #7f8fa6;
  position: absolute;
  top: 28px;
  left: 40px;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 2em;
  opacity: 0.5;
}

.astronaut__head-visor-flare2 {
  background-color: #718093;
  position: absolute;
  top: 40px;
  left: 38px;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 2em;
  opacity: 0.3;
}

.astronaut__backpack {
  background-color: #bfbfbf;
  position: absolute;
  top: 90px;
  left: 47px;
  width: 86px;
  height: 90px;
  content: '';
  border-radius: 8px;
}

.astronaut__body {
  background-color: #e6e6e6;
  position: absolute;
  top: 115px;
  left: 55px;
  width: 70px;
  height: 80px;
  content: '';
  border-radius: 8px;
}

.astronaut__body__chest {
  background-color: #d9d9d9;
  position: absolute;
  top: 140px;
  left: 68px;
  width: 45px;
  height: 25px;
  content: '';
  border-radius: 6px;
}

.astronaut__arm-left1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 127px;
  left: 9px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-30deg);
}

.astronaut__arm-left2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 102px;
  left: 7px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-12deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-right1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 113px;
  left: 100px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
}

.astronaut__arm-right2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 78px;
  left: 141px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-thumb-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 110px;
  left: 21px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(-35deg);
}

.astronaut__arm-thumb-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 90px;
  left: 133px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(20deg);
}

.astronaut__wrist-left {
  background-color: #e67e22;
  position: absolute;
  top: 122px;
  left: 6.5px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-15deg);
}

.astronaut__wrist-right {
  background-color: #e67e22;
  position: absolute;
  top: 98px;
  left: 141px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-10deg);
}

.astronaut__leg-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 50px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(10deg);
}

.astronaut__leg-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 108px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(-10deg);
}

.astronaut__foot-left {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 43px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}

.astronaut__foot-right {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 111px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(-10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}

@media only screen and (max-width:1490px) {
	html {
		font-size:50%;
	}
	footer {
		.top {
			.container:last-child {
				.col30:nth-child(2) {
					padding-top: 20px;
					a {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width:1250px) {
	html {
		font-size:40%;
	}
	.insights-section {
		.col25 {
			width:50%;
		}
		&.category-page {
			.col25 {
				width:50%;
			}
		}
	}
	.grow-together {
		.container {
			.social-buttons {
				padding-top: 20px;
				a {
					margin-top: 20px;
				}
			}
		}
	}
	.where-we-are {
		.map-area {
			.left {
				width:45%;
			}
			.map {
				width:55%;
			}
		}
	}
	.get-in-touch {
		&.type3 {
			.right {
				.image {
					width:75%;
				}
			}
		}
	}
	.faq {
		.row {
			.question {
				font-size:20px;
			}
		}
	}
}

@media only screen and (max-width:1100px) {
	header {
		nav {
			display:none;
		}
		.responsive-nav {
			display:block;
		}
	}
	.submenu {
		&.submenu-top {
			display:none;
			ul {
				margin-left: 0;
			}
		}
	}
	.subpage-hero {
		&.margin-top2 {
			margin-top: 92px;
		}
	}
	.what-we-do-overview {
		.top {
			h2 .underlined {
				&:after {
					bottom:-5px;
				}
			}
			.right {
				.image {
					width:70%;
				}
			}
		}
		.bottom {
			.col30 {
				padding-right: 20px;
			}
		}
	}
}

@media only screen and (max-width:1000px) {
	.submenu {
		&.submenu-top {
			ul {
				li {
					&:first-child {
						margin-left: -10px;
					}
					padding:0 10px;
				}
			}
		}
	}
	.hero-slider {
		p {
			font-size:16px;
		}
	}
	.introduction {
		p {
			font-size:18px;
		}
	}
	.grow-together {
		.container {
			.right {
				.image {
					width:250px;
					height:218px;
				}
			}
		}
		&.type2 {
			.container {
				.right {
					.image {
						width:250px;
						height:219px;
					}
				}
			}
		}
	}
	footer {
		.top {
			.container:first-child {
				.col30 {
					width:50%;
					&:last-child {
						width:100%;
					}
				}
			}
			.container:last-child {
				.col30:first-child {
					width:45%;
				}
				.col30:last-child {
					width:15%;
				}
			}
		}
	}
	.featured-insight {
		.container {
			padding-top: 0;
			display:flex;
			flex-wrap:wrap;
			flex-direction: column-reverse;
		}
		.left {
			margin-top: 30px;
			width:100%;
			padding-right: 0;
		}
		.right {
			width:100%;
		}
	}
	.filter-insights {
		.custom-select {
			font-size:13px;
		}
	}
	.quick-contact {
		.col30 {
			font-size:14px;
			h3 {
				font-size:3rem;
			}
		}
	}
	.study-content {
		.box {
			width:100%;
		}
	}
}

@media only screen and (max-width:800px) {
	.astronaut {
		display:none;
	}
	.error {
		top:250px;
		left:20px;
		right:20px;
		.error__title {
			font-size:30px;
		}
	}
	.moon, .moon__crater {
		display:none;
	}
	.pageAlign, .pageAlign2, .pageAlign3, .pageAlignFluid {
		padding:0 30px;
	}
	.hero-slider {
		.pageAlign {
			padding:0 30px 0 70px;
		}
	}
	.subpage-hero {
		.pageAlign2 {
			padding:0 30px 0 70px;
		}
	}
	.introduction {
		.buttons {
			a.arrow-link {
				margin:20px 45px;
			}
		}
	}
	.our-difference {
		p {
			font-size:16px;
		}
	}
	footer {
		.col30 {
			padding:0 !important;
			margin:20px 0 !important;
			width:100% !important;
			text-align: left !important;
		}
		.bottom {
			.right {
				width:100%;
				margin-top: 10px;
			}
		}
	}
	.our-values {
		.values {
			.value {
				width:50%;
			}
		}
	}
}

@media only screen and (max-width:680px) {
	header {
		a.logo {
			margin-right: 0;
		}
	}
	.submenu {
		ul {
			li {
				padding:0 10px;
				font-size:14px;
			}
		}
	}
	.hero-slider {
		h1,h2 {
			font-size:18px;
		}
	}
	.hero-dots {
		margin-top: -40px;
	}
	.proud-members {
		p {
			font-size:16px;
		}
	}
	.insights-section {

		.col25 {
			width:100%;
			padding:12px 0;
		}
		&.category-page {
			.col25 {
				width:100%;
			}
		}
	}
	.wrapper {
		.category-posts {
			width:100%;
		}
		.category-side-menu {
			width:100%;
			padding-left: 0;
		}
	}
	.grow-together {
		background:#fff;
		&:before {
			width:100%;
		}
		&:after {
			display:none;
		}
		.container {
			display:block;
			.left {
				width:100%;
				padding:20px;
			}
			.right {
				margin:0 -30px;
				background:$blue-color;
				width:auto;
				float:none;
				height:0;
				padding-bottom: 100%;
				.image {
					margin-left: 0;
				}
			}
		}
	}
	.pageAlignSpecial {
		padding:0 40px;
	}
	.who-we-worked-with {
		.logo-slider {
			.slide {
				padding:5px;
			}
		}
		.slick-arrow {
			left:-30px;
			&.slick-prev {
				right:-30px;
			}
		}
	}
	.ad {
		.content {
			font-size:18px;
		}
	}
	footer {
		.top {
			.col30:first-child {
				h3 {
					font-size:18px;
				}
			}
		}
	}
	.what-we-do-overview {
		.top {
			.container {
				flex-wrap:wrap;
				flex-direction: column-reverse;
			}
			.left {
				width:100%;
			}
			.right {
				width:100%;
				.image {
					display:block;
					margin:0 auto;
				}
			}
		}
		.bottom {
			.col30 {
				padding-right: 0;
				width:100%;
			}
			.buttons {
				a {
					margin:10px 40px 10px 0;
				}
			}
		}
	}
	.how-we-work {
		.row {
			.left {
				padding:20px 10px 20px 10px;
			}
			.right {
				padding:20px 10px 20px 10px;
			}
		}
	}
	.get-in-touch {
		.col50 {
			width:100%;
		}
	}
	.our-approach {
		.left {
			width:100%;
		}
		.right {
			width:100%;
			padding-left: 0;
			margin-top: 30px;
		}
	}
	.our-values {
		.values {
			margin:0;
			.value {
				width:100%;
				padding:21px 0;
				&:after {
					left:0;
					right:0;
				}
			}
		}
	}
	.our-story {
		.image {
			position:static;
			width:100%;
			padding-bottom: 30%;
		}
		.right {
			width:100%;
			padding:0 0 10rem 0;
		}
	}
	.where-we-are {
		.map-area {
			.left {
				font-size:14px;
				padding:20px;
				width:100%;
				a.arrow-link {
					margin-top: 20px;
				}
			}
			.map {
				width:100%;
				padding-bottom: 100%;
			}
		}
	}
	.filter-insights {
		.filter-text {
			display:none;
		}
		.custom-select {
			width:47%;
			margin:1%;
			font-size:11px;
		}
	}
	.featured-insight {
		padding-top: 5rem;
		h2 {
			font-size:3rem;
		}
	}
	.quick-contact {
		.col30 {
			width:100%;
			font-size:14px;
			margin:10px 0;
			h3 {
				font-size:3rem;
			}
		}
	}
	.get-in-touch {
		.container {
			flex-wrap:wrap;
			flex-direction: column-reverse;
		}
		&.type3 {
			.left {
				width:100%;
			}
			.right {
				width:100%;
				.image {
					float:none;
					margin:0 auto;
					width:50%;
				}
			}
		}
	}
	.faq {
		.row {
			.question {
				font-size:16px;
				padding-top:15px;
				padding-bottom: 15px;
			}
		}
	}
	.study-content {
		.box {
			.left {
				width:100%;
				padding-bottom: 100%;
			}
			.right {
				width:100%;
			}
		}
	}
	.testimonials {
		.content {
			font-size:16px;
		}
	}
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing, div.wpcf7-mail-sent-ng, div.wpcf7-aborted {
	background:$blue-color;
	border:none !important;
	color:#fff;
	padding:15px 25px !important;
	margin:50px 30px 0 30px !important;
	clear:both;
}
div.wpcf7-mail-sent-ok {
	background:$link-color;
	border:none !important;
	color:#fff;
	padding:15px 25px !important;
	margin:50px 30px 0 30px !important;
	clear:both;
}
span.wpcf7-not-valid-tip {
	color:#fff !important;
	background: $blue-color;
    padding: 5px;
    color: #fff;
    font-size: 11px !important;
}
div.wpcf7 .ajax-loader {
	margin:-73px 0 0 10px !important;
}
div.wpcf7 {
	.ajax-loader {
		background-image:url(../images/ajax-loader.svg) !important;
	}
}
.pagination {
	padding-bottom:10rem;
	background:#e8ebef;
	color:$blue-color;
	text-align: center;
	a, span {
		display:inline-block;
		padding:10px;
		font-weight:700;
		color:$blue-color;
	}
	span {
		background:#fff;
		color:$link-color;
	}
	a:hover {
		color:$link-color;
	}
	a.next, a.prev {
		margin:0 20px;
	}
}

.post-navigation {
	color:$blue-color;
	text-align: center;
	padding-top: 10rem;
	div {
		display:inline-block;
	}
	a, span {
		display:inline-block;
		padding:10px;
		font-weight:700;
		color:$blue-color;
		margin:0 40px;
	}
	span {
		background:#fff;
		color:$link-color;
	}
	a:hover {
		color:$link-color;
	}
	a.next, a.prev {
		margin:0 20px;
	}

}
